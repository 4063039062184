<template>
  <div id="register">
    <v-form
      ref="form"
      v-model="form.valid"
      lazy-validation
    >
      <v-container fluid>
        <v-row>
          <v-col sm="6">
            <v-row>
              <v-col align="center">
                <h1>Registration</h1>
                <p>Please enter the information below and click Register button to create an account.  <br>You will receive an email with a verify account action.  <br><br><b>If you do not receive the email please check your junk or spam folder.</b></p>
                <p>Already have an account? <router-link class="ma-1" x-large color="error" to="/login" plain>Back to Login</router-link></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      filled
                      label="Name"
                      v-model="form.fields.name"
                      placeholder="Please enter your full name"
                      :rules="form.validation.nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      filled
                      label="Email"
                      v-model="form.fields.email"
                      placeholder="name@domain.com"
                      :rules="form.validation.emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      filled
                      label="Password"
                      v-model="form.fields.password"
                      placeholder="Password"
                      type="password"
                      :rules="form.validation.passwordRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-text-field
                      filled
                      label="Confirm Password"
                      v-model="form.fields.confirm"
                      placeholder="Confirm"
                      type="password"
                      :rules="form.validation.confirmRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col sm="12" align="right">
                    <v-btn color="primary" dark x-large @click.prevent="register">
                      Register
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="6" align="center">
            <pp-shades>
              <h2 class="white--text">Why Register?</h2>
							<h3 class="white--text">Although a free game experience, we ask our users to register to enable all our features.<br><br>Registered users will have all access and be able to track their progress.<br><br>Please view our Terms of Use or Privacy Policy for more information.</h3>
            </pp-shades>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
    <v-dialog
      v-model="form.resendValidation"
      outlined
      width="450px"
    >
      <v-card class="mx-auto">
        <v-card-title>Looks like you have an existing account.</v-card-title>
        <v-card-text><div class="text-center">Would you like to be resent a verification email?</div></v-card-text>
        <v-card-actions>
          <v-flex class="text-right">
            <v-btn color="error" class="mr-1" x-large text @click.prevent="form.resendValidation = false">
              Cancel
            </v-btn>
            <v-btn color="success" x-large @click.prevent="resendVerificationMail">
              Yes
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ApiService from '@/services/ApiService.js';

  export default {
    name: "Register.vue",
    data() {
      return {
        form: {
          resendValidation: false,
          valid: true,
          validation:{
            nameRules: [
              v => !!v || 'Name is required',
            ],
            emailRules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            passwordRules: [
              v => !!v || 'Password is required',
            ],
            /* eslint-disable no-unused-vars */
            confirmRules: [
              v => !!v || 'Please confirm your password',
              v => this.form.fields.password === this.form.fields.confirm || 'Passwords must match',
            ],
          },
          fields:{
            name: "",
            email: "",
            password: "",
            confirm: "",
          },
        },
      };
    },
    methods: {
      errorFor(field) {
        return this.errors != null && this.errors[field] ? this.errors[field] : null;
      },
      resendVerificationMail() {
        let self = this;
        if(this.$refs.form.validate()){
          ApiService
            .post("/account/resend-verification-mail", this.form.fields)
            .then(() => {
              self.$root.snackbar.message = `Please verify your email address: ${this.form.fields.email}`;
              self.$root.snackbar.open = true;
              self.$router.replace('/');
            })
        }
      },
      register() {
        if(this.$refs.form.validate()){
          let self = this;
          ApiService
            .post("/account/register", this.form.fields)
            .then(() => {
              self.$root.snackbar.message = `Please verify your mail address: ${this.form.fields.email}`;
              self.$root.snackbar.open = true;
              self.$router.replace('/');
            })
            .catch((err) => {
              let fields = ['name','email','password','confirm'];
              let output = '';
              for (var i = 0; i < fields.length; i++) {
                let error = err.response.data.errors[fields[i]];
                if(error != undefined){
                  output += error;
                  if(fields[i] === 'email'){
                    self.form.resendValidation = true;
                  }
                }
              }
              self.$root.snackbar.message = output;
              self.$root.snackbar.open = true;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  h1 {
    line-height: 1.2em;
  }
  textarea {
    height: 13rem;
  }
  .map {
    border-radius: 10px;
    overflow: hidden;
    border: solid 1px #cfd8dc;
  }
</style>
